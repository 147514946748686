import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "distribution-channels-api-reference"
    }}>{`Distribution Channels API Reference`}</h1>
    <p><a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.entur.io/distribution/v2/api-docs"
      }}>{`See the API reference`}</a></p>
    <Swagger url="https://api.entur.io/distribution/v2/api-docs" mdxType="Swagger" />
    <h2 {...{
      "id": "note-about-permissions-when-migrating-to-distribution-channels-api-v2"
    }}>{`Note about permissions when migrating to distribution channels API V2`}</h2>
    <p>{`In Distribution Channels V1 API you get permissions through the Distribution Channel API. This is no longer supported as
the single source of truth for permissions is now the Permission Store. See below for an explanation of how to do this. `}</p>
    <h3 {...{
      "id": "how-to-to-authorize-access-by-permission-client-or-distribution-channel"
    }}>{`How to to authorize access by permission-client or distribution channel`}</h3>
    <h5 {...{
      "id": "there-are-two-ways-to-solve-this-the-preferable-way-is-to-use-permission-client-as-following"
    }}>{`There are two ways to solve this. The preferable way is to use permission-client as following:`}</h5>
    <ol>
      <li parentName="ol">{`Add package dependency `}<inlineCode parentName="li">{`permissionClient`}</inlineCode></li>
      <li parentName="ol">{`Configure permission-client and subscribe to permission "Salgskanal" as shown in documentation for permission-client`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`  permission:
    permissionCache:
      - {...}
    businessCapabilities:
      - {...}
    responsibilityTypes:
      - Salgskanal.Id, Salgskanal, LES
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Authorize endpoint with either PreAuthorize/PostAuthorize or two methods from permission-client`}
        <ol parentName="li">
          <li parentName="ol">{`Secure your endpoint with @PreAuthorize(hasPermission(#distributionChannelId, 'Salgskanal#Id', 'LES')`}</li>
          <li parentName="ol">{`Secure your endpoint with 2 methods from permission-client "checkResponsibilitySetPermission(Object principal, String operationAndType, String objectKey, Access access)"
or "getResponsibilitySet(Object principal, String operationAndType, Access access)"`}</li>
        </ol>
      </li>
    </ol>
    <p>{`This implementation will now allow users or clients which has permission "salgskanal" and with token orgId listed in organisationAgreements for given distributionChannelId to access this distribution channel data object.`}</p>
    <h5 {...{
      "id": "the-alternative-way-which-is-almost-as-similar-to-distribution-v1-is-to-add-requestquery-includeorganisationagreements-as-shown-in-the-next-line"
    }}>{`The alternative way, which is almost as similar to distribution V1 is to add requestQuery "includeOrganisationAgreements" as shown in the next line.`}</h5>
    <p>{`GET /distribution-channels/{id}?includeOrganisationAgreements=true`}</p>
    <p>{`This implementation will now fetch agreements from permission-store by given distributionChannelId and add it as a field
in request body. This should not be used as a permanent solution since it limits our implementation of authorization
and neither is it used as intended.`}</p>
    <p>{`Note: It will not work for GET /distribution-channels since it is an expensive operation to permission-store.`}</p>
    <h3 {...{
      "id": "how-to-see-if-users-or-clients-have-access-to-certain-distributionchannelid-via-entur-partner"
    }}>{`How to see if users or clients have access to certain distributionChannelId via entur-partner`}</h3>
    <p>{`To see if your user's or client's organisation has an agreement to a given distributionChannel, please navigate to Distribution Channels in Entur Partner `}<a parentName="p" {...{
        "href": "https://entur-partner.dev.entur.org"
      }}>{`https://entur-partner.dev.entur.org`}</a>{`
To see if your user or client has permission "salgskanal", please navigate to Permission Admin in Entur Partner `}<a parentName="p" {...{
        "href": "https://entur-partner.dev.entur.org"
      }}>{`https://entur-partner.dev.entur.org`}</a></p>
    <p>{`Note: The owner of distribution channels has implicit access to its own Distribution Channels. For now these
at least these will not show up in the returned list of `}<inlineCode parentName="p">{`Agreement`}</inlineCode>{`s and for technical reasons Entur gets automatically
access to Distribution Channels that have type set to "onBoard".`}</p>
    <h2 {...{
      "id": "headers"
    }}>{`Headers`}</h2>
    <p>{`It is required that all consumers identify themselves by using the header ET-Client-Name.`}</p>
    <p>{`Request header:`}</p>
    <code>ET-Client-Name: your-client-id</code>
    <p>{`The structure of ET-Client-Name should be: "company-application".`}</p>
    <p>{`Examples:`}</p>
    <p>{`"brakar-journeyplanner"
"fosen_utvikling-infoplakat"
"nor_way_bussekspress-reiseplanlegger"`}</p>
    <p>{`Request example:`}</p>
    <code>curl -X GET -H "ET-Client-Name: your_company-your_application" -H "Authorization: Bearer ..." https://api.entur.io/distribution/...</code>
    <p>{`This header is used to identify where the request is coming from and is a required header parameter that should be supplied with each request.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      